.section {
    border-radius: 0.25rem;
    border: 1px solid #d8dde6;
    width: 100%;
}

.body {
    box-shadow:
        0 2px 4px 0 rgba(0, 0, 0, 0.1),
        0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.body-wrap {
    display: block;
}
