.ps-error-boundary-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .boundary-back-btn {
        all: unset;
        color: #215db0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
