.input-slot-modal {
    .input-slot-tabs {
        margin-bottom: -16px;
        .ant-tabs-nav-more {
            padding: 8px !important;
        }
        .ant-tabs-nav-more:focus {
            outline: none;
        }
    }
    .slds-modal__content {
        height: 100%;
    }
    .input-slot-modal-spinner {
        /* fit spinner to  modal  */
        margin: 48px 0px 80px 0px; 
    }
}
/* input-slot-tabs-more should be outside the input-slot-modal because is in deferent parent element */
.input-slot-tabs-more {
    z-index: 10000;
}

.input-slot-form-container {
    padding: 16px;

    .slot-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .accordion-json-slot {
        margin-inline: -16px;

        .json-slot-textarea {
            overflow: hidden;
        }
    }

    .pair-key-chain {
        display: flex;
        gap: 16px;
        .slds-form-element {
            flex: 1;
        }
        .pair-label {
            flex: 1;
        }
    }
}
