.Search {
    height: 100%;
    display: flex;
    flex-flow: column;

    .tab-content {
        flex-flow: column nowrap; /* changed from row to column? */
    }

    .top {
        height: 100%;
        min-width: 10em; /* min-width helps correctly size the datatable*/
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 0;
        overflow: hidden;
    }

    .results {
        position: relative; /* to position the 'onboarding' modal */
        flex: 1 1 0;
        overflow: hidden;
    }

    .search-container {
        position: relative;
    }

    .navigation {
        position: absolute;
        min-width: 20em;
        max-width: 40em;
        padding: 0;
        z-index: 9090; /* over 'onboarding' modal*/
        top: 2em;
        left: 0;
    }

    .search-box {
        padding: 0 0.25rem 0 0.25rem;
        cursor: text;
        /* min-height: 2em; */
        min-height: 2.5em;
        line-height: 2.37em;
    }

    .search-box:focus-within {
        border: 1px solid #1b96ff;
        box-shadow: 0 0 3px #0176d3;
    }

    .search-term {
        background-color: #efefef;
        padding: 3px;
        max-width: 20em;
    }

    .backdrop {
        z-index: 9089;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
    }

    .search-placeholder {
        margin-left: 0.45rem;
        color: #666666;
    }

    .search-icon {
        margin-left: 0.5rem;
        --sds-c-icon-color-foreground-default: #aaaaaa;
    }

    .search-button {
        width: 7em;
    }

    .suggestions {
        position: absolute;
        width: 100%;
        max-width: 100%;
        padding: 0;
        z-index: 9090; /* over 'onboarding' modal*/
        left: 0;
    }
}
