.textarea-container {
    width: 100%;
}

.textarea-container-item {
    font-family: monospace;
    font-size: 13px;
}

.textarea {
    min-height: 30px;
    max-height: 500px;
}
