.setup-page-container {
    height: 100%;
    display: flex;
    flex-flow: column;

    .um-left {
        height: 100%;
        min-width: 21em;
        flex: 0 1 auto;
        overflow: hidden;
        padding: 12px 8px;
        border: 1px solid #c9c9c9;
        border-radius: 4px;
    }
    .um-right {
        width: 100%;
        height: 100%;
        min-width: 10em;
        overflow: hidden;
        position: relative;
    }

    /* Styling and side menu for the setup page to look same with the tree in the other pages */
    .ant-side-menu-custom-style {
        border-inline-end: initial !important;

        .ant-menu-item-group {
            .ant-menu-item-group-title {
                padding: 8px;
                color: #444;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;
                line-height: 15px;
            }
            .ant-menu-item-group-list {
                .ant-menu-item {
                    border-radius: initial;
                    color: #1c2127;
                    padding: 6px 0px 6px 41px;
                    margin: initial;
                    height: initial;
                    width: initial;
                    .ant-menu-title-content {
                        display: block;
                        line-height: 18px;
                    }
                }
                .ant-menu-item-selected {
                    background-color: #e9f3fe;
                    -webkit-box-shadow: #1b96ff 4px 0 0 inset;
                    box-shadow: #1b96ff 4px 0 0 inset;
                }

                .ant-menu-item:focus {
                    outline: initial;
                }
            }
        }
    }
}
