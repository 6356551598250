.Explore {
    height: 100%;
    display: flex;
    flex-flow: column;

    .pill {
        background-color: #efefef;
    }

    .pill-label {
        margin-right: 0.25rem;
        margin-left: 0.2rem;
        max-width: 30em;
    }
    .record-item-container {
        margin: 0.0625rem 0.3rem 0.0625rem 0;
    }
}
