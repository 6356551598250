.build-drop-box-container {
    width: 300px;
    margin-right: 10px;
    margin-left: -5px;
    overflow: hidden;

    .card-drop-box {
        .slds-card__header {
            display: none;
        }
        .slds-card__body {
            min-height: 100%;
            margin: initial;
            display: flex;

            .box-content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin: 7px 5px 0px 5px;
                height: auto;
            }
        }
    }
}
