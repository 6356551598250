.setup-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9070; /* in front of the spinner (z-index = 98 (backdrop) / 9060 (icon)) and plot tooltips (z-index = 9 and 10) */
    background-color: rgba(0, 0, 0, 0.5);

    .setup-modal {
        margin: 0 auto;
        height: 100%;
        min-width: 10rem;
        max-width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    .setup-image {
        max-width: 10rem;
        margin: 0 auto;
    }

    .progress-text-center {
        text-align: center;
    }
}
