.PsCompositionGrid {
    height: 100%;
}

.grid-item-composition {
    width: 420px;
    min-width: 420px; /*prevents shrinking on narrow window size */
    height: 360px;
    margin: 5px;
}
