.form-wrapper-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    padding: 12px 16px;

    .form-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* min-height: 37px; */

        .form-tl {
            font-weight: 700;
            font-size: 16px;
        }
    }

    .form-m-description {
        margin-top: 12px;
        font-size: 0.75rem;
        line-height: 1.25;
        color: #444;
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        padding: 0.5rem 1rem;
        background: #f3f3f3;
    }

    .form-btn-br-top {
        border-top: 1px solid #c9c9c9;
        margin: 12px -16px;
    }
    .form-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
