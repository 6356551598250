.custom-record-table {
    overflow-x: auto;
    padding-bottom: 20px;

    .slds-table_header-fixed_container {
        width: fit-content;
    }
    .slds-table_header-fixed_scroller {
        overflow-y: hidden !important;
        width: fit-content;
        padding-inline: 4px;
    }

    .grip-handle {
        margin-left: 0px;
    }

    table {
        width: fit-content !important;
    }

    .expanded {
        visibility: visible;
    }
    .collapsed {
        visibility: collapse;
    }
}
