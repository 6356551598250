html {
    height: 100%;
}

body {
    /* height: 100%; */
    height: calc(100% - 90px);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* shared */

.tab-content {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    background-color: white;
    overflow: hidden;

    /* Remove box shadow for all slds-cards that are on a white background */
    .slds-card {
        box-shadow: none;
    }
}

.left {
    height: 100%;
    min-width: 15em;
    flex: 0 1 auto;
    overflow: hidden;
}

.left-card {
    height: 100%;
}

.right {
    flex: 1 1 0;
    height: 100%;
    min-width: 10em; /* min-width helps correctly size the datatable*/
    position: relative;
}

.card-main-title-lh32 {
    line-height: 32px; /* Line-height is used to align headers title with right-aligned buttons in different cards, ensuring the headers match the button height. */
}

.message {
    background-color: var(--slds-g-color-brand-base-95, #f5fcff);
    border-color: var(--slds-g-color-brand-base-95, #bee6ff);
    color: var(--slds-g-color-neutral-base-10, #464646);
    padding: 1rem;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.coloredText {
    font-weight: normal;
    color: #009edb;
}
