.title {
    display: flex;
}

.fill-parent {
    width: 100%;
    height: 100%;
}

.tooltip {
    position: absolute;
    z-index: 9;
    display: block;
    opacity: 0.8;
    visibility: hidden;
    background: #000000;
    border-radius: 7.5px;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    padding: 6px;
    border: 0px;
    max-width: 600px;
}
.tooltip.static-top {
    width: max-content;
    top: 3.5em;
    transform: translate(-50%);
    left: 50%;
}

.tooltip-nubbin_top::before {
    width: 8px;
    height: 8px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    background-color: inherit;
    left: 50%;
    top: -4px;
    margin-left: -4px;
}

.tooltip-nubbin_left::before {
    width: 8px;
    height: 8px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    background-color: inherit;
    top: 50%;
    left: -4px;
    margin-top: -4px;
}

.tooltip-nubbin_right::before {
    width: 8px;
    height: 8px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    background-color: inherit;
    top: 50%;
    right: -4px;
    margin-top: -4px;
}

.tooltip-nubbin_bottom::before {
    width: 8px;
    height: 8px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    background-color: inherit;
    left: 50%;
    bottom: -4px;
    margin-left: -4px;
}

.axisZone {
    position: absolute;
    z-index: 10;
    opacity: 0; /* set to 0.5 for debugging */
    visibility: hidden;
    color: #fff;
    background: #000000;
    cursor: pointer;
}

.header-actions-icons {
    margin: -11px -15px 0 0;
    white-space: nowrap;
}

.ps-pattern-chart-error-boundary {
    width: 500px;
    height: 390px;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.chart-container {
    height: 100%;
}

.chart-header {
    height: 5em;
    overflow: hidden;
    margin-bottom: 0;
}

.chart-header-content {
    display: flex;
    justify-content: space-between;
}

.chart-body-grid {
    height: calc(100% - 6em);
    cursor: pointer;
}

.chart-body-detail {
    height: 100%;
}

.chart-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative; /* needed for correct positioning of tooltips */
    overflow: hidden;
}

.chart-table-container {
    overflow: auto;
    height: 100%;
    width: 100%;
    position: absolute;
}
