.metaText {
    margin-left: 25px;
    opacity: 0.5;
}

.emptyIcon {
    margin-right: 25px;
}

.treeItem:hover .dragAndDropIcon {
    opacity: 1;
}

.dragAndDropIcon1,
.dragAndDropIcon2,
.dragAndDropIcon3 {
    margin-right: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.row1:hover .dragAndDropIcon1,
.row2:hover .dragAndDropIcon2,
.row3:hover .dragAndDropIcon3 {
    opacity: 1;
}
