.ps-top-menu-bar-container {
    display: flex;
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 9999;
    min-height: 46px;

    .logo-wrapper {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo-img {
            padding-left: 10px;
            max-width: 70px;
            cursor: pointer;
        }
    }

    .tab-container {
        width: 100%;

        .ps-top-tab-navigation {
            outline: none;

            .ps-tab-link {
                text-decoration: none;
            }
        }
    }

    .setting-profile-container {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;

        .ps-setup-btn:hover {
            color: #1b96ff;
        }
        .ps-setup-active {
            color: #1b96ff;
        }

        .profile-container {
            padding-right: 15px;
        }
    }
}

.top-menu-bb {
    border-bottom: 3px solid #1b96ff;
}

.top-menu-bs {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
}
