.navigationHeader {
    position: sticky;
    top: 45px;
    height: 50px;
    background: rgb(3, 45, 96);
    color: rgb(255, 255, 255);
    display: flex;
    align-content: center;
    align-items: center;
    font-family:
        Salesforce Sans,
        Arial,
        sans-serif;
}

.navigationHeaderItem {
    min-width: 0;
    height: 100%;
    display: flex;
    flex-grow: 1;
}

.navigationHeaderItemRight {
    min-width: 0;
    height: 100%;
    display: flex;
    flex-grow: 0;
}

.navigationHeaderButton {
    color: white;
    align-content: center;
}

.borderRight {
    border-right: 1px solid white;
}

.navigationHeaderButton:hover {
    background: rgb(1, 68, 134);
    cursor: pointer;
}

#navHeaderPopover {
    position: absolute;
    top: 50px;
    border: 0;
    border-radius: var(--lwc-borderRadiusMedium, 0.25rem);
    z-index: 9090;
    background-color: var(--lwc-colorBackgroundAlt, rgb(255, 255, 255));
    display: inline-block;
    box-shadow: none;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
}

#navHeaderPopoverBackdrop {
    z-index: 9089;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    visibility: hidden;
}
