/* TODO: this contains mostly css for the login form, let's just move this to the login modal component*/
.login-signup-container {
    width: 400px;
    margin: 100px auto;
}

.login-signup-heading {
    font-size: 18px;
    margin: 15px 0 10px 0;
    text-align: center;
}

.user-inputs {
    margin: 10px 20px 10px 20px;
}

.login-signup-button {
    margin: 30px 20px 20px 20px;
}

.login-signup-link {
    text-align: center;
    margin-bottom: 35px;
}

.login-forgot-password {
    margin-top: 2px;
}

.form-title {
    margin: 0 20px 0 20px;
}

.login-submit-button {
    width: 100%;
    margin-bottom: 15px;
}

/* .page-right {
    width: 100%;
    height: 100%;
    min-width: 10em;
    position: relative;
    overflow: hidden;

    .right-content {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        max-height: 100%;
    }
} */

.progress-center {
    text-align: center;
    margin: auto;
    max-width: 600px;
}
