.Home {
    height: 100%;
    padding: 15px;
}

.full {
    position: relative;
    height: 100%;
}
.card-content {
    min-height: 5em;
}

.card-text {
    height: 2em;
}

.static {
    position: absolute;
    background: white;
}

.static:hover {
    opacity: 0;
    cursor: pointer;
}

.gif-hover-active {
    position: relative;
}

.card-content {
    text-align: center;
}
