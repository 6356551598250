.profile-page-container {
    height: 100%;
    display: flex;
    flex-flow: column;

    .um-left {
        height: 100%;
        min-width: 21em;
        flex: 0 1 auto;
        overflow: hidden;

        .slds-card {
            .slds-card__header {
                margin: 0px 0 -2px -5px;
            }

            .slds-card__body {
                overflow-y: auto;
                height: calc(100vh - 178px);
            }
        }
    }
    .um-right {
        width: 100%;
        height: 100%;
        min-width: 10em;
        overflow: hidden;
        position: relative;
    }
}
