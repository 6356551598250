.Store {
    height: 100%;
    display: flex;
    flex-flow: column;

    .pill {
        background-color: #efefef;
    }

    .pill-label {
        margin-right: 0.25rem;
        max-width: 30em;
    }
}
