.PsRecordGrid {
    height: 100%;
}

.layout {
    overflow-x: scroll;
}

.grid {
    overflow-x: scroll;
}

/* .react-grid-item {
    transition-duration: 0ms !important;
} */

.react-grid-item.static .icon-overlay {
    display: none; /* hide the delete icon when the grid item is static */
}

.react-grid-item.react-grid-placeholder {
    background: #1677ff !important;
}

.editModeBorder {
    border: 3px solid #005fb2;
}

/* table.css for blueprint ui table */
/* TODO: this applies everywhere now, should sit on application level??*/
.bp5-table-selection-region {
    border: none !important;
}
.bp5-dark .bp5-table-selection-region {
    border: none !important;
}
.bp5-table-container {
    outline: none !important;
}

.bp5-table-quadrant-scroll-container,
.bp5-table-column-headers,
.bp5-table-container,
.bp5-table-menu,
.bp5-table-row-headers {
    background-color: #f3f3f3 !important;
}

.bp5-table-thead.bp5-table-column-header-tr {
    font-weight: bold !important;
}

.bp5-table-cell,
.bp5-table-row-name,
.bp5-table-column-name {
    font-size: 13px !important;
}
