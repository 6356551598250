/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} */

/* body {
    font-family: Arial, sans-serif;
} */

.container {
    display: flex;
    flex-wrap: wrap;
}

.sidebar {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 10px;
    min-height: 100vh;
    gap: 5px;
}

.content {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: calc(100% - 400px);
}

.top-content,
.bottom-content {
    flex: 1;
    margin-bottom: 10px;
    border: 1px solid #0b0000;
    padding: 10px;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .top-content,
    .bottom-content {
        margin-bottom: 10px;
    }
}

.build {
    height: 100%;
    display: flex;
    flex-flow: column;
}

/* Divider */
.divider {
    height: 100%;
    background-color: rgba(224, 224, 224, 0.8);
    margin: 0 10px;
    top: 0;
    bottom: 0;
    width: 2px;
}
