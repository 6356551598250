.ps-global-header {
    margin-bottom: 90px;

    .ps-top-main-bar-nav {
        position: fixed;
        display: flex;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
        align-items: center;
        width: 100%;
        height: 50px;
        top: 0;
        left: 0;
        z-index: 100;
        padding: 0px 16px;

        /* style same with salesforce design global header logo*/
        .img-logo-container {
            width: 200px;
            height: 40px;
            background-image: url("/public/assets/images/PsLogo.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
        }
    }

    .ps-setup-btn:hover {
        color: #1b96ff;
        background-color: rgba(21, 137, 238, 0.1);
    }
    .ps-setup-active {
        color: #1b96ff;
        background-color: rgba(21, 137, 238, 0.1);
    }

    .avatar-pr {
        abbr {
            cursor: pointer;
        }
    }
}

.header-profile-custom-content-item {
    display: flex;
    cursor: pointer;
    margin-bottom: 15px;
}

.header-profile-custom-content-row {
    display: flex;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 18px;
    margin-left: -28px;
    margin-right: -28px;
    margin-bottom: 5px;
}

.header-profile-custom-content-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.header-profile-custom-content-item-icon {
    margin-right: 10px;
}

.header-profile-popover-body-avatar-user-info {
    display: flex;
    gap: 10px;
}
.user-name {
    max-width: 230px;
}

.user-email {
    max-width: 250px;
    margin-top: -2px;
}

.user-info {
    margin-top: -15px;
}

.logout {
    margin-left: 17px;
    margin-top: 5px;
}

.border-element {
    left: 0;
    right: 0;
    height: 1px;
    background-color: #cecece;
    margin: 5px -30px 5px -30px;
}

.badge-text {
    display: flex;
}

.img-region {
    width: 40px;
    height: 20px;
}
.region-wrapper {
    background: #f3f3f3;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    border-radius: 2px;
    padding: 2px;
}
.region-badge {
    display: flex;
}

.avatar {
    margin-left: -25px;
    margin-top: -10px;
    margin-right: 10px;
}

.user-container {
    display: flex;
}
