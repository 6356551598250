.pointer {
    cursor: pointer;
}

.illustration-medium {
    height: 8em;
    max-width: 8em;
}

.source {
    height: 2em;
}

.runStatus {
    height: 1em;
}
